@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";

.FilterSelectMenu {
  // margin-bottom: 20px;
  margin-right: 20px;
  @include tablet-lg {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  position: relative;
  .form-control {
    height: 52px;
  }
  .selectmenu {
    padding-left: 50px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    color: $text-green;
    padding-right: 30px;
    &:focus {
      box-shadow: none;
    }
  }
  .selectmenuWihtouticon {
    padding: 0.375rem 0.75rem;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    color: $text-green;
    padding-right: 30px;
    background-image: url(../../assets/images/dd-arrow.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    &:focus {
      box-shadow: none;
    }
  }
  .filter-icon {
    position: absolute;
    left: 15px;
    top: 13px;
  }
}
