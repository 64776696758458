@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";
.Page404 {
  margin-top: 66px;
  background-color: $bg-offwhite;
  @include tablet-lg {
    margin-top: 0px;
  }
  .page404-blcok {
    padding: 80px 20px;
    min-height: calc(100vh - 285px - 66px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  h1 {
    color: $text-green;
    font-size: 40px;
  }
  p {
    font-family: $fontRegular;
    margin: 0px;
  }
  .btn-green {
    background-color: $bg-white;
    border: 1px solid $text-green;
    text-transform: uppercase;
    color: $text-green;
    font-family: $font600;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 20px;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include mobile {
    .page404-blcok {
      img {
        width: 120px;
      }
    }
  }
}
