@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";
.add-block {
  border-radius: 5px;
  position: relative;
  height: 416px;
  width: 100%;
  background-size: cover;
  @include flex;
  flex-direction: column;
  justify-content: center;

  .add-content {
    padding: 50px;
    padding-left: 60px;
    @include laptop {
      padding: 15px;
      padding-left: 15px;
    }
    z-index: 9;

    height: 100%;
    @include flex-justify(space-between);
    flex-direction: column;
    h2 {
      font-family: $font600;
      color: $text-white;
      font-size: 18px;
      margin-bottom: 20;
      padding-top: 100px;
    }
    p {
      font-family: $font300;
      color: $text-white;
      font-size: 14px;
      margin-bottom: 0;
      margin-top: 0px;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

    }
    .add-btn-block {
      width: 200px;
      margin: 0 auto;
    }
  }
  &::after {
    content: "";
    border-radius: 5px;
    position: absolute;
    left: 0px;
    background-color: rgba($color: $bg-green, $alpha: 0.6);
    width: 100%;
    height: 100%;
  }

  .btn-white-border {
    background-color: transparent;
    color: $text-white;
    min-width: 100%;
    height: 54px;
    line-height: 42px;
    font-size: 18px;
    text-transform: uppercase;
    border: 1px solid $text-white;
    font-family: $fontRegular;
    &:hover {
      background-color: #fff;
      color: $black-text-color;
    }
  }
}
