@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";
.CheckOut {
  margin-top: 66px;
  @include tablet-lg {
    margin-top: 0px;
  }
  .contact-section {
    background-color: $bg-offwhite;
    padding-top: 60px;
    min-height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mobile {
      padding-block: 60px;
    }
    .brdcumb-block {
      @include flex-justify(space-between);
      .brd-link {
        color: $black-text-color;
        text-decoration: none;
        font-size: 18px;
        @include tablet-lg {
          font-size: 14px;
        }
      }
      h2 {
        font-size: 30px;
        font-family: $font600;
        @include tablet {
          font-size: 22px;
        }
        @include mobile {
          font-size: 18px;
        }
      }
    }

    .select-profile {
      /* inline SVG */
      background: #ffffff;
      background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
      background-position: right 10px center;
      background-repeat: no-repeat;
      background-size: auto 50%;
      border-radius: 5px;
      padding: 10px 30px 10px 10px;
      // disable default appearance
      opacity: 0.3;
      outline: none;
      color: $black-text-color;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      &::-ms-expand {
        display: none;
      }
    }
    .form-check-input:checked {
      background-image: none;
      background-color: $auth-primary-color;
      border-color: $auth-primary-color;
    }
    .pay-method-form {
      @media screen and (max-width: 400px) {
        display: flex;
        flex-wrap: wrap;
        // gap: 10px;
      }
    }
    .card-detail-div {
      .p-head {
        font-weight: 400;
        font-size: 16px;
        color: #393939;
      }
      .input-profile {
        font-weight: 300;
        color: #8e8e8e;
        border: none;
      }
      .half-width {
        width: 40%;
        @include tablet-lg {
          width: 75%;
        }
        @include tablet {
          width: 40%;
        }
        @include mobile {
          width: 100%;
        }
      }
      .full-width {
        @include mobile {
          width: 100% !important;
        }
      }
      .date-box {
        input[type="date"]::-webkit-calendar-picker-indicator {
          color: rgba(0, 0, 0, 0);
          opacity: 1;
          display: block;
          background: url("../../assets/images/ic-date.svg") no-repeat;
          width: 20px;
          height: 20px;
          border-width: thin;
        }
      }
    }
    .btn-pay {
      background-color: $btn-green;
      color: #fff;
      border-radius: 10px;
      width: 30%;
      @include tablet-lg {
        width: 40%;
      }
      @include tablet {
        width: 30%;
      }
      @include mobile {
        width: 50%;
      }
    }
    .course-thumbnail {
      max-width: 200px;
      height: 105px;
      margin-right: 20px;
      @include tablet {
        max-width: 100%;
        height: 200px;
        margin-right: 0px;
        margin-bottom: 10px;
      }
      flex: 1;
      display: flex;
      position: relative;
      @include tablet {
        display: flex;
      }
      .course-thumbnail-img {
        width: 100%;
        object-fit: cover;
        object-position: center center;
        border-radius: 5px;
      }
    }
    .text-img {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: $black-text-color;
      .text-green {
        color: $auth-primary-color;
        font-size: 18px;
      }
      .vat-charge-text {
        font-size: 14px;
      }
      .line {
        text-decoration: line-through;
      }
      .textBlack {
        color: $auth-primary-color;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .btn-green {
    height: 38px;
    border-radius: 10px;
    line-height: 26px;
    background-color: $bg-green;
    color: $text-white;
    min-width: 200px;
    text-transform: uppercase;
  }
  .img-status {
    height: 65px;
    width: 65px;
  }
  .crossIcon {
    display: none;
  }
  .popupinfo {
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      /* identical to box height */
      text-align: center;
      color: #000000;
    }
  }
}
.payment-by-drop {
  @media screen and (max-width: 400px) {
    margin: unset !important;
    width: 100%;
  }
  .FilterSelectMenu {
    margin-right: 0;
    select {
      font-size: 16px !important;
    }
  }
}
.number {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
