@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";

.CustomPagination {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  span {
    margin: 0 5px;
  }
  .CustomPaginationButton {
    button {
      background: transparent;
      border: none;
      margin-left: 5px;
    }
  }
}
