@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";
.MyCourse {
  margin-top: 66px;
  @include tablet-lg {
    margin-top: 0px;
  }
  .main-content {
    min-height: 780px;
    background-color: rgba($color: $bg-offwhite, $alpha: 0.8);
    margin-left: 200px;
    @include tablet-lg {
      margin-left: 0;
      padding-bottom: 58px;
    }
    .brdcumb-block {
      @include flex-justify(space-between);

      // @include mobile {
      //   a {
      //     font-size: 16px !important;
      //   }
      //   span {
      //     font-size: 16px !important;
      //   }
      // }
    }
    .brd-link {
      color: $black-text-color;
      text-decoration: none;
      font-size: 18px;
      @include tablet-lg {
        font-size: 14px;
      }
    }
    .text-green {
      color: $text-green;
      @include tablet-lg {
        font-size: 14px;
      }
    }
    .radio-toolbar input[type="radio"] {
      display: none;
    }
    .radio-toolbar label {
      display: inline-block;
      padding-right: 50px;
      @include tablet-lg {
        font-size: 14px;
      }
      @include laptop {
        padding-right: 15px;
      }
      cursor: pointer;
    }
    .radio-toolbar input[type="radio"]:checked + label {
      color: $text-green;
      font-family: $font600;
    }

    .filter-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      margin-bottom: 30px;
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .filter-result {
      font-size: 14px;
      font-family: $font300;
    }
    .disable-block {
      opacity: 0.6;
      pointer-events: none;
    }
    .course-list-block {
      padding: 15px;
      background: $bg-white;
      border-radius: 5px;
      margin-bottom: 30px;
      @include mobile {
        margin-bottom: 15px;
      }
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      @include tablet {
        flex-direction: column;
      }
      @include flex;
      .course-thumbnail {
        max-width: 135px;
        height: 105px;
        margin-right: 20px;
        @include tablet {
          max-width: 100%;
          height: 200px;
          margin-right: 0px;
          margin-bottom: 10px;
        }
        flex: 1;
        display: flex;
        position: relative;
        @include tablet {
          display: flex;
        }
      }
      h3 {
        font-size: 16px;
        margin-bottom: 0;
      }
      h6 {
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 0;
      }
      .list-content {
        flex: 1;
        display: flex;
        justify-content: space-between;
      }
      .list-content-block {
        flex-direction: column;
        @include justify-align(space-between, flex-start);
        position: relative;
      }
      .btn-status-area {
        flex: 1 1;
        min-width: 120px;
        max-width: 120px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include mobile {
          min-width: 90px;
          max-width: 90px;
        }
      }
      .list-date {
        font-size: 12px;
      }
      .link-green {
        color: $green;
        font-family: $fontRegular;
        font-size: 14px;
        text-decoration: none;
        float: right;
      }
      .course-status {
        font-size: 14px;
        text-align: right;
      }
      .course-favourite {
        position: absolute;
        right: 5px;
        top: 5px;
      }
      .btn-green {
        background-color: $btn-green;
        color: $text-white;
        min-width: 100px;
        height: 34px;
        line-height: 22px;
        font-size: 12px;
        text-transform: none !important;
        &:hover {
          background-color: #fff;
          color: $btn-green;
        }
        @include tablet-lg {
          font-size: 12px;
          min-width: 80;
          height: 34px;
          line-height: 22px;
        }
      }
    }
    .course-thumbnail-img {
      width: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: 5px;
    }
  }
  .right-content {
    width: 100%;
    padding-top: 20px;
    padding-left: 0px;
    @include tablet-lg {
      padding-left: 0px;
    }
    .fixHeight {
      min-height: 78vh;
    }
    .my-course-fill {
      .FilterSelectMenu {
        margin: 0;
      }
      @media screen and (max-width: 500px) {
        h2 {
          width: 154px;
          font-size: 16px !important;
        }
      }
    }
    .related-course {
      margin-top: 20px;
    }
  }
  .popup .popupBackground .popupSection {
    padding: 20px;
    max-width: 630px;
    width: 90%;
    justify-content: space-between;
    align-items: center;
  }
  .popupheading h2 {
    font-size: 30px;
    font-family: $font600;
  }
  .popupinfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    .cou-name {
      flex-direction: column;
      display: flex;
      max-width: 100%;
      align-items: flex-start;
    }
    .inst-image {
      width: 135px;
      height: 105px;
      overflow: hidden;
      border-radius: 5px;
      margin-right: 10px;
      min-width: 135px;
      @include mobile {
        width: 100px;
        height: 80px;
        min-width: 100px;
      }
      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
    h3 {
      font-size: 18px;
      text-align: center;
      font-family: $font300;
      margin-top: 0px;
      margin-bottom: 0;
      @include mobile {
        font-size: 16px;
      }
    }
    p {
      font-family: $font300;
      font-size: 14px;
      margin-bottom: 0;
      @include mobile {
        font-size: 12px;
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    @include mobile {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .star {
      margin: 0 5px;
      @include mobile {
        margin: 0 2px;
      }
      img {
        @include mobile {
          width: 70%;
        }
      }
    }
  }
  .on {
    color: #f9c32c;
  }
  .off {
    color: #9f9fa3;
  }
  .add-section {
    position: sticky;
    top: 90px;
    bottom: 0;
    /* z-index: 2; */
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;

    @include tablet-lg {
      all: unset;
      width: 100%;
      padding: 10px;
    }
    .view-all-ads {
      text-align: end;
      margin-right: 10px;
      color: #3e8181;

      @include tablet-lg {
        text-align: start;
      }
    }
  }
  .textAr {
    width: 100%;
    margin-top: 20px;
    border: 1px solid #3e8181;
    border-radius: 5px;
    resize: none;
    margin-bottom: 10px;
    padding: 10px;
    min-height: 90px;
    outline: none;
    box-shadow: none;
    font-size: 12px;
    font-family: $font300;
  }
  .popup .popupBackground .popupSection .popupheading {
    justify-content: flex-start;
  }
  .thankYou {
    .popupSection {
      width: 35% !important;
    }
    h3 {
      margin-bottom: 10px;
    }
  }
}
