@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";
.tncpage {
  margin-top: 66px;
  @include tablet-lg {
    margin-top: 0px;
  }
  .banner-img {
    height: 200px;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
    background: #fafafa;

    @include tablet {
      height: 200px;
    }
    @include mobile {
      height: 200px;
    }
    @include flex-align(center);
    .pagename {
      h1 {
        font-family: $font600;
        color: $black-text-color;
        margin-bottom: 0px;
        @include tablet {
          font-size: 22px;
        }
        @include mobile {
          font-size: 18px;
        }
      }
      a {
        font-family: $font600;
        color: $black-text-color;
        text-decoration: none;
        @include tablet {
          font-size: 14px;
        }
        @include mobile {
          font-size: 10px;
        }
      }
    }
  }

  .tncContent {
    background-color: $bg-offwhite;
    // padding-block: 60px;
    @include mobile {
      //   padding-block: 60px;
    }
   
    p {
      color: $black-text-color;
      font-family: $font300;
      font-size: 18px;
      text-align: left;
      span{

        background: transparent !important;
      }


      @include mobile {
        text-align: left;
        font-size: 14px;
      }
    }
    h1 {
      color: $black-text-color;
      font-size: 30px;
      font-family: $font600;
      text-align: left;
      text-transform: capitalize;

      @include mobile {
        text-align: center;
        font-size: 22px;
      }
    }
  }
  h3 {
    font-family: $font600;
    color: $black-text-color;
    margin-bottom: 0px;
    text-align: center;
    padding: 30px 0px;

    @include tablet {
      font-size: 30px;
    }
    @include mobile {
      font-size: 18px;
    }
  }
}
