@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";


 
 .svg-loader {
    height: 5rem;
    vertical-align: top;
 }

 .loader-main{
    background-color: #FFFFFF;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 1000;
    top:0px;
    left: 0px;
    .loader-icon{
       width: 120px;
       height: 120px;
       position: absolute;
       transform: translate(-50%, -50%);
       left: 50%;
       top: 50%;

    }
 }