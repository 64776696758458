@font-face {
  font-family: "RundText-SemiBold";
  src: url("./../../assets/fonts/RundText-SemiBold.woff2") format("woff2"),
    url("./../../assets/fonts/RundText-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RundText-Regular";
  src: url("./../../assets/fonts/RundText-Regular.woff2") format("woff2"),
    url("./../../assets/fonts/RundText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RundText-Light";
  src: url("./../../assets/fonts/RundText-Light.woff2") format("woff2"),
    url("./../../assets/fonts/RundText-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
