@import "../../../common/styles/variables.scss";
@import "../../../common/styles/mixins.scss";

.login-main {
  min-height: 100vh;
  // min-width: 100vw;
  display: flex;
  background: rgba(118, 145, 140, 0.8);
  .login-slider {
    width: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    flex: 1;
  }
  .langauge-dd {
    display: flex;
    position: absolute;
    top: 1%;
    right: 25px;
    select {
      background-color: #fff;
      color: #3e8181;
      height: 54px;
      line-height: 42px;
      border-radius: 10px;
      text-transform: uppercase;
      border-color: #3e8181;
      padding: 0 15px;
      margin-left: 10px;
      -webkit-appearance: none;
      appearance: none;
      -moz-appearance: none;
      background: url(../../../assets/images/header-dd-arrow.svg) no-repeat;
      background-position: right 12px center;
      padding-right: 30px;

      @include tablet-lg {
        font-size: 14px;
        height: 40px;
        line-height: 28px;
        position: unset;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
  .login-form {
    width: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $light-gray;
    flex-direction: column;
    padding-top: 22vh;
    h4 {
      font-size: 50px;
      font-family: $font600;
      color: $black-text-color;
    }
    h5 {
      font-size: 25px;
      font-family: $fontRegular;
      color: $black-text-color;
    }
    label {
      font-family: $fontRegular;
    }
    .fill-icon {
      padding-right: 40px;
    }
    .filled-icon {
      position: absolute;
      right: 10px;
      bottom: 8px;
    }
    .password-highlighter {
      ul {
        margin: 0;
        padding: 0;
        margin-top: 20px;
        li {
          display: block;
          position: relative;
          padding-left: 20px;
          &::before {
            content: "";
            border: 1px solid $green;
            height: 10px;
            width: 10px;
            border-radius: 10px;
            position: absolute;
            left: 3px;
            top: 6px;
          }
        }

        .highlighter {
          &::before {
            content: "";
            background-color: $green;
            height: 10px;
            width: 10px;
          }
        }
      }
    }
    .form-check-input:checked {
      background-color: $green;
      border-color: $green;
    }
  }
  .main-form-block {
    width: 465px;
    max-width: 100%;
    .form-group {
      margin-top: 15px;
    }
    .btn-green {
      background-color: $btn-green;
      color: #fff;
    }
    .errorText {
      color: $google-color;
      font-size: $super-small-font;
      // position: absolute;
      margin-top: 5px;
      margin-bottom: 0px !important;
    }
  }
  .link-green {
    color: $green;
    font-family: $font300;
    text-decoration: none;
    font-size: 18px;
  }
  .font18 {
    color: $black-text-color;
    font-family: $font300;
    font-size: 18px;
    text-decoration: none;
  }
}
.popup .popupBackground .popupSection {
  padding: 40px;
}
.main_tnc_block {
  max-width: 700px;
  max-height: 400px;
  overflow: auto;
  padding-right: 40px;
  scrollbar-width: thin;
  scrollbar-color: #3e8181 #ffffff;

  @include mobile {
    padding-right: 20px;
  }
}
/* Chrome, Edge, and Safari */
.main_tnc_block::-webkit-scrollbar {
  width: 16px;
}

.main_tnc_block::-webkit-scrollbar-track {
  background: #ffffff;
}

.main_tnc_block::-webkit-scrollbar-thumb {
  background-color: #3e8181;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
.langauge-d-sec {
  display: flex;
  justify-content: center;
}
.langauge-d-sec .langauge-dd {
  margin-left: 0;
}
.langauge-d-sec img {
  cursor: pointer;
  margin-left: 5px;
}
.langauge-d-sec ul {
  display: none;
  position: absolute;
  right: 0;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #212529;
  display: none;
  font-size: 1rem;
  list-style: none;
  margin: 0;
  min-width: 2rem;
  padding: 0.5rem 0;
  text-align: left;
  cursor: pointer;
}

.dropdown:hover ul {
  display: block !important;
}
