$auth-background-color: #a3b6b3;
$auth-primary-color: #3e8080;
$super-small-font: 12px;
$small-font: 14px;
$midium-font: 22px;
$large-font: 40px;
$facebook-color: #3b5998;
$twitter-color: #26a6d1;
$google-color: #f14336;
$gray-color: #9fa2b4;
$gray-normal: #ebebeb;
$gray-extra-light: #fcfdfe;
$gray-extra-lighest: #f0f1f7;
$black-text-color: #393939;
$dark-green: #1a4840;
$light-primary-color: #a3b6b3;
$accent-light: #dde2ff;
$dashboard-background: #e5e5e5;

$active-tab-color: #e5ebfb;

// user side button color

$btn-green: #3e8181;
$light-gray: #f4f4f4;
$green: #3e8181;
$text-white: #fff;
$text-green: #3e8181;
$bg-white: #fff;
$bg-black: #000;
$footer-bg-color: #4f5d5a;
$imgbg: #4f5d5a;
$bg-offwhite: #FAFAFA;
$bg-green: #3e8181;
$bg-midgray: #e2dfdf;
// $light-gray:#D9D9D9;
//font style

$fontRegular: "RundText-Regular";
$font600: "RundText-SemiBold";
$font300: "RundText-Light";
$fontInter: 'Inter';

