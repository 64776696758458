@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";
.MySpace {
  margin-top: 66px;
  @include tablet-lg {
    margin-top: 0px;
  }
  .FilterSelectMenu {
    margin-right: 0px;
    margin-top: 20px;
    .form-control {
      height: 44px;
    }
  }

  .main-content {
    min-height: 780px;
    background-color: rgba($color: $bg-offwhite, $alpha: 0.8);
    margin-left: 200px;
    @include tablet-lg {
      margin-left: 0;
      padding-bottom: 58px;
    }
    .brdcumb-block {
      @include flex-justify(space-between);
    }
    .brd-link {
      color: $black-text-color;
      text-decoration: none;
      font-size: 18px;
      @include tablet-lg {
        font-size: 14px;
      }
    }
    .text-green {
      color: $text-green;
      @include tablet-lg {
        font-size: 14px;
      }
    }
    .radio-toolbar input[type="radio"] {
      display: none;
    }
    .radio-toolbar label {
      display: inline-block;
      padding-right: 50px;
      @include tablet-lg {
        font-size: 14px;
      }
      @include laptop {
        padding-right: 15px;
      }
      cursor: pointer;
    }
    .radio-toolbar input[type="radio"]:checked + label {
      color: $text-green;
      font-family: $font600;
    }

    .filter-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      margin-bottom: 30px;
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .filter-result {
      font-size: 14px;
      font-family: $font300;
    }
    .minHigthFix {
      min-height: 631px;
    }
    .list-block-main {
      background-color: $bg-white;
      padding: 15px;
      border-radius: 5px;
      position: relative;
      @include tablet-lg {
        display: block;
        padding-bottom: 30px;
      }
      .rmdp-wrapper {
        @include tablet-lg {
          margin: 0 auto;
        }
      }
      .disable-block {
        opacity: 0.6;
        pointer-events: none;
      }
      .course-list-block {
        padding: 15px;
        background: $bg-white;
        border-radius: 5px;
        margin-bottom: 30px;

        @include mobile {
          margin-bottom: 15px;
        }
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        @include tablet {
          flex-direction: column;
        }
        @include flex;
        .course-thumbnail {
          max-width: 135px;
          height: 105px;
          margin-right: 20px;
          @include tablet {
            max-width: 100%;
            height: 200px;
            margin-right: 0px;
            margin-bottom: 10px;
          }
          flex: 1;
          display: flex;
          position: relative;
          @include tablet {
            display: flex;
          }
        }
        h3 {
          font-size: 16px;
          margin-bottom: 0;
        }
        h6 {
          font-size: 12px;
          margin-top: 5px;
          margin-bottom: 0;
        }
        .list-content {
          flex: 1;
          display: flex;
          justify-content: space-between;
        }
        .list-content-block {
          flex-direction: column;
          @include justify-align(space-between, flex-start);
          position: relative;
        }
        .btn-status-area {
          flex: 1 1;
          min-width: 120px;
          max-width: 120px;
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          @include mobile {
            min-width: 90px;
            max-width: 90px;
          }
        }
        .list-date {
          font-size: 12px;
        }
        .link-green {
          color: $green;
          font-family: $fontRegular;
          font-size: 14px;
          text-decoration: none;
          float: right;
        }
        .course-status {
          font-size: 14px;
        }
        .course-favourite {
          position: absolute;
          right: 5px;
          top: 5px;
        }
        .btn-green {
          background-color: $btn-green;
          color: $text-white;
          min-width: 100px;
          height: 34px;
          line-height: 22px;
          font-size: 12px;
          text-transform: uppercase;
          &:hover {
            background-color: #fff;
            color: $btn-green;
          }
          @include tablet-lg {
            font-size: 12px;
            min-width: 80px;
            height: 34px;
            line-height: 22px;
          }
        }
      }
      .section-title {
        @include justify-align(space-between, center);
        align-items: flex-start;
        margin-bottom: 20px;
        h3 {
          margin-bottom: 0px;
          font-family: $fontRegular;
          font-weight: normal;
          font-size: 18px;
        }
        .FilterSelectMenu {
          margin: 0;
          position: absolute;
          right: 15px;
          top: 5px;
          width: auto;
          .form-control {
            font-size: 12px;
            font-family: $font300;
          }
        }
      }
      .image-small {
        margin-top: 5rem;
        display: flex;
        justify-content: center;
      }
    }
    .graph-block {
      height: 60vmin;
      margin-top: 20px;
      background-color: $bg-white;
      padding: 5%;
      border-radius: 5px;
      position: relative;
      @media (max-width: 769px) {
        height: 80vmin;
      }
      .FilterSelectMenu {
        margin: 0;
        position: absolute;
        right: 15px;
        top: 5px;
        width: auto;
        .form-control {
          font-size: 12px;
          font-family: $font300;
        }
      }
      h4 {
        font-size: 18px;
        font-family: $fontRegular;
      }
    }
    .main-calender-block {
      background-color: $bg-white;
      padding: 15px;
      border-radius: 5px;
      width: 100%;
      min-height: 631px;
      @include tablet-lg {
        margin-top: 20px;
        min-height: unset;
      }
      h3 {
        margin-bottom: 0px;
        font-family: $fontRegular;
        font-weight: normal;
        font-size: 18px;
        // padding-left: 10px;
      }
      .calender-block {
        // .fc .fc-bg-event{
        //  background: transparent;
        //   background-color: transparent !important;
        // }
        .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
          left: 0;
          position: absolute;
          right: 0;
          bottom: 0;
          text-align: center;
          font-size: 8px;
          color: #fff;
        }
        .fc .fc-daygrid-more-link {
          cursor: pointer;
          position: relative;
          z-index: 4;
          color: #fff;
          background: #3e8181;
          padding: 2px 2px 0 2px;
          border-radius: 1px;
          bottom: 0;
          font-size: 11px;
          text-decoration: none;
        }
        .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
          left: 0;
          position: unset;
          right: 0;
          bottom: 13px;
        }
        .fc-theme-standard {
          height: 350px !important;
          @include minmax-width(992, 1054) {
            height: 450px !important;
          }
          @include mobile {
            height: 440px !important;
          }
        }
        .fc-daygrid-block-event .fc-event-title {
          font-size: 11px;
          @include mobile {
            font-size: 8px;
          }
        }
        .fc-direction-ltr .fc-daygrid-event.fc-event-end,
        .fc-direction-rtl .fc-daygrid-event.fc-event-start {
          margin-right: 2px;
          background: unset;
          border: 0;
          font-size: 8px;
          text-align: center;
        }

        .fc-col-header-cell-cushion {
          text-decoration: none !important;
        }
        .fc-daygrid-day-top {
          cursor: pointer;
          .fc-daygrid-day-number {
            text-decoration: none !important;
          }
        }
      }
    }
    .course-thumbnail-img {
      width: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: 5px;
    }
  }
  .right-content {
    width: 100%;
    padding-top: 20px;
    padding-left: 0px;
    @include tablet-lg {
      padding-left: 0px;
    }
    // @include mobile {
    //   a {
    //     font-size: 16px !important;
    //   }
    //   span {
    //     font-size: 16px !important;
    //   }
    // }
  }
  .link-green {
    color: $green;
    font-family: $fontRegular;
    font-size: 14px;
    text-decoration: none;
    float: right;
  }
  .zoom-details {
    margin-bottom: 10px;
    h6 {
      font-size: 14px;
      color: $black-text-color;
      margin-bottom: 5px !important;
    }
  }
  .related-course-block {
    .related-course {
      height: 60vmin;
      margin: 20px 0 0;
      @media (max-width: 769px) {
        height: 80vmin;
      }
    }
  }
  .apexcharts-canvas {
    margin: 0 auto;
  }
  .list-block-main.px-0 {
    .course-list-block {
      margin-bottom: 16px;
    }
  }
  // .fc .fc-scrollgrid {
  //   width: 370px;
  //   max-width: 100%;
  // }
  .fc-scroller {
    overflow: hidden !important;
  }
  .fc .fc-daygrid-day-frame {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none;
  }
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  .fc-daygrid-day-frame {
    flex-direction: column;
  }
  // .fc-prev-button.fc-button.fc-button-primary {
  //   display: none;
  // }
  // .fc-next-button.fc-button.fc-button-primary,
  .fc-timeGridDay-button.fc-button.fc-button-primary,
  .fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active,
  .fc-timeGridWeek-button.fc-button.fc-button-primary {
    display: none;
  }

  .fc-toolbar .fc-toolbar-chunk:nth-child(3) {
    display: none;
  }
  .fc .fc-toolbar {
    display: flex;
    flex-direction: row-reverse;
    align-items: unset;
    padding-left: 12px;
  }
  .fc-col-header-cell-cushion {
    color: $text-green;
  }
  .fc-daygrid-day-number {
    color: $black-text-color;
  }
  .fc-day-today {
    background-color: $black-text-color !important;
    border-radius: 5px;
    .fc-daygrid-day-number {
      color: #fff;
    }
  }
  .zoom-link {
    word-break: break-all;
    a {
      text-decoration: underline;
      font-size: 12px;
      color: $text-green;
    }
  }
}
.fc .fc-button-primary {
  background-color: $bg-green !important;
}
@media (max-width: 768px) {
  .fc .fc-toolbar-title {
    font-size: 18px !important;
  }
  .fc .fc-bg-event .fc-event-title {
    margin: 0px !important;
    font-size: 11px;
    @include mobile {
      font-size: 8px;
    }
  }
  .fc-toolbar {
    align-items: center !important;
  }
}
