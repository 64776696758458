@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";

.No-data-main {
  h1 {
    font-size: 30px;
    @include mobile{
      font-size: 18px !important;
    }
  }
  .No-coursse-text {
    .text-content {
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      @include mobile{
        font-size: 14px !important;
      }
    }
    .course-btn {
      display: flex;
      justify-content: center;
      .view-all-btn {
        min-width: 200px;
        @include mobile{
          min-width: 130px;
        }
      }
    }
  }
}
