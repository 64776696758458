@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";

.header-main {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 9999;
  .container {
    max-width: 98%;
  }
  .nav-item.dropdown.dd-arrow {
    background-image: url(../../assets/images/header-dd-arrow.svg);
    background-position: right top 17px;
    background-repeat: no-repeat;
    padding-right: 8px;
  }
  .dropdown {
    &:hover {
      .dropdown-menu {
        display: block;
        margin-top: 0; // remove the gap so it doesn't close
      }
    }
    .dropdown-menu[data-bs-popper]{
      left: auto !important;
    }
  }

  .navbar-brand img {
    // height: 40px;
    @include tablet-lg {
      height: 30px;
    }
  }

  .btn-grn {
    background-color: #fff;
    color: $btn-green;
    min-width: 200px;
    height: 54px;
    line-height: 42px;
    border-radius: 10px;

    border-color: $btn-green;
    &:hover {
      color: #fff;
      background-color: $btn-green;
    }
    @include tablet-lg {
      font-size: 14px;
      min-width: 120px;
      height: 40px;
      line-height: 28px;
    }
  }
  .mobile-logo {
    @include tablet-lg {
      height: 30px;
      margin-top: 8px;
    }
  }
  .nav-item {
    margin: 0 10px;
    &:last-child {
      margin-right: 20px;
    }
  }
  .navbar-light .navbar-toggler {
    position: relative;
  }
  @include tablet-lg {
    .navbar-light .navbar-toggler {
      color: rgba(0, 0, 0, 0.55);
      border-color: rgba(0, 0, 0, 0.1);
      position: absolute;
      right: 10px;
      top: 17px;
    }
  }
  @include tablet-lg {
    .navbar.navbar-expand-lg.navbar-light .btn-grn {
      position: absolute;
      right: 62px;
      top: 17px;
    }
  }
  .navbar {
    // position: absolute;
    top: 0;
    z-index: 99;
    width: 100%;
    background-color: #fff;
    @include tablet-lg {
      position: absolute;
    }
  }
  .navbar-collapse {
    @include tablet-lg {
      height: 100vh;
      overflow: auto;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
  .user-information {
    .nav-item {
      margin-right: 0px;
    }
    @include tablet-lg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 15px;
      right: 73px;
    }
    &.forUserProfile {
      right: 14px;
    }
    .profil-username {
      text-transform: capitalize;
      @include mobile {
        display: none;
      }
    }
    @include justify-align(space-between, center);
    .user-img {
      width: 40px;
      height: 40px;
      margin-left: 10px;
      background-color: rgb(224, 224, 224);
      overflow: hidden;
      img {
        height: 40px;
      }
      @include justify-align(center, center);
      border-radius: 40px;

      @include mobile {
        margin-left: 0px;
      }
    }

    .dropdown-menu {
      right: 0;
    }
  }
  .user-porfile-main {
    .dropdown-menu {
      width: 200px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      font-size: 14px;
      padding: 0;
      overflow: hidden;
      padding-top: 10px;
      border: none;
      margin-top: 10px;
    }
    .dd-profile {
      align-items: center;
      display: flex;
      border-bottom: 1px solid #a8a9ae;
      padding-bottom: 10px;
      @include mobile {
        padding-left: 15px;
      }

      .profil-username.ms-2 {
        border-radius: 5px;
        font-size: 14px;
        @include mobile {
          display: unset;
        }
      }
      .quik-profilelink {
        font-size: 12px;
        color: $black-text-color;
        text-transform: none !important;
      }
      a {
        font-size: 12px;
        color: $text-green;
        text-decoration: none;
        text-transform: none !important;
      }
    }
  }
  .noti-main-block {
    .noti-bell {
      position: relative;
      margin-right: 10px;
      display: inline-block;
      align-items: center;
      border-right: 1px solid #ccc;
      padding-right: 20px;
      .notiDot {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: #1a4840;
        border: 2px solid #3e8080;
        left: 8px;
      }
    }
    .dropdown-menu {
      width: 275px;
      min-height: 100px;
      max-height: 300px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      font-size: 14px;
      padding: 0;
      overflow-y: scroll;
      padding-top: 0px;
      border: none;
      @include mobile {
        margin-top: 0;
        left: 50%;
        transform: translate(-50%, 10px);
      }
      li {
        a {
          .notification-title {
            font-size: 16px;
            color: #000;
          }
          p {
            word-break: break-word;
            width: 275px;
            white-space: break-spaces;
            padding-right: 30px;
            text-align: left;
            font-size: 12px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .link-green {
    color: $text-green;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    width: auto;
    right: 20px;
    padding: 0;
    border: 0;
    @include tablet-lg {
      right: 0px;
      position: relative;
    }
  }
  .nav-link.text-active {
    color: #3e8181;
    // text-decoration: underline;
  }
}
// .dropdown-menu {
//   display: block !important;
// }

.header-main .navbar-light .navbar-toggler {
  top: 26px;
  border: none;
}
.langauge-dd {
  margin-left: 10px;
  align-items: center;

  .dropdown-menu {
    right: 0;
    min-width: 5rem;
    .dropdown-item {
      cursor: pointer;
    }
  }
  select {
    background-color: #fff;
    color: #3e8181;
    height: 54px;
    line-height: 42px;
    border-radius: 10px;
    text-transform: uppercase;
    border-color: #3e8181;
    padding: 0 15px;
    margin-left: 10px;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background: url(../../assets/images/header-dd-arrow.svg) no-repeat;
    background-position: right 12px center;
    padding-right: 30px;

    @include tablet-lg {
      font-size: 14px;
      height: 40px;
      line-height: 28px;
      position: absolute;
      right: 60px;
      top: 15px;
    }
    &:focus-visible {
      outline: none;
    }
  }
}

.mob-menu {
  position: relative;
  .langauge-dd {
    bottom: 0;
    left: 0;
    top: unset;
    p {
      font-size: 15px;
      font-family: $fontRegular;
      margin: 0px;
      margin-bottom: 5px;
      color: #3e8080;
    }
    select {
      left: 0;
      top: unset;
      right: 0;
      margin: 0;
    }
  }
}
