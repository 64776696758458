@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";

.video-main {
  position: relative;
  width: 100%;
  height: 460px;
  .react-player{
    height: 460px !important;
    width: auto !important;
  }
  video::-internal-media-controls-download-button {
    display:none;
   }

   video::-webkit-media-controls-enclosure {
        overflow:hidden;
   }

   video::-webkit-media-controls-panel {
        width: calc(100% + 30px); 
   }
  video {
    width: 100%;
    border-radius: 10px;
  }
  iframe {
    width: 100%;
    border-radius: 10px;
    height: 400px;
    @include mobile {
      height: 250px;
    }
  }
  #player{
    width: 100% !important;
  }
}
video::-internal-media-controls-download-button {
  display:none;
}

video::-webkit-media-controls-enclosure {
  overflow:hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}