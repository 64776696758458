.react-multi-carousel-track{
    justify-content: center !important;
    align-items: center !important;
}
.react-multiple-carousel__arrow--left {
    left: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--right {
    right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow{
    background-color: rgb(115, 117, 117) !important;
    opacity: 0.25 !important;
}