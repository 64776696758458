@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";
.contactPage {
  margin-top: 66px;
  @include tablet-lg {
    margin-top: 0px;
  }
  .banner-img {
    height: 400px;
    background-position: right top;
    background-repeat: no-repeat;
    background-size: cover;
    // margin-top: 76px;
    @include tablet {
      height: 200px;
    }
    @include mobile {
      height: 200px;
    }
    @include flex-align(center);
    .pagename {
      h1 {
        font-family: $fontRegular;
        color: $black-text-color;
        margin-bottom: 0px;
        @include tablet {
          font-size: 22px;
        }
        @include mobile {
          font-size: 22px;
        }
      }
      a {
        font-family: $font600;
        color: $black-text-color;
        text-decoration: none;
        @include tablet {
          font-size: 14px;
        }
        @include mobile {
          font-size: 10px;
        }
      }
    }
  }
  .contact-section {
    background-color: $bg-offwhite;
    padding-block: 60px;
    @include mobile {
      padding-block: 60px;
    }
    .section2Heading{
      font-family: $fontRegular;
      font-size: 32px;
      color: #393939;
      @include mobile{
        font-size: 22px;
      }
    }
    p {
      color: $black-text-color;
      font-family: $font300;
      font-size: 18px;
      text-align: center;
      @include mobile {
        font-size: 14px;
      }
    }
    h2 {
      color: $black-text-color;
      font-size: 30px;
      font-family: $font600;
      text-align: center;
      @include mobile {
        font-size: 22px;
      }
    }
    .contact-textarea {
      TextArea {
        height: 165px;
      }
    }
    .errorText {
      color: $google-color;
      font-size: $super-small-font;
      // position: absolute;
      margin-top: 5px;
      text-align: left;
    }
    .contact-submit-btn{
      min-width: 120px;
    }
  }

  .cta-section {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding-block: 60px;
    @include flex-align(center);
    min-height: 300px;
    h2 {
      text-align: left;
      color: $text-white;
      @include mobile {
        font-size: 22px;
      }
    }
    h6 {
      text-align: left;
      color: $text-white;
      a {
        color: $text-white;
      }
    }
  }
  .section-title {
    @include flex-align-direction(center, space-between);
  }
  .become-suppliers {
    background-color: $light-gray;
    padding-block: 60px;
    h1 {
      font-family: $font600;
      color: $black-text-color;
      font-size: 30px;
    }
    p {
    }
    .abt-image {
      position: relative;
      display: flex;
      justify-content: center;
      max-width: 100%;
      @include tablet-lg {
        margin-top: 20px;
        display: block;
      }
    }

    .abt-image::before {
      content: " ";
      position: absolute;
      top: -20px;
      left: -20px;
      width: 100%;
      height: 100%;
      background: rgba($color: $imgbg, $alpha: 0.5);
      border-radius: 6px;
      @include tablet-lg {
        content: unset;
      }
    }

    .abt-image img {
      position: relative;
      z-index: 1;
      border-radius: 6px;
    }
  }
  .btn {
    border-radius: 10px;
  }
  .btn-green {
    background-color: $btn-green;
    color: $text-white;
    min-width: 200px;
    height: 54px;
    line-height: 46px;
    &:hover {
      background-color: #fff;
      color: $btn-green;
    }
    @include tablet-lg {
      font-size: 14px;
      min-width: 120px;
      height: 40px;
      line-height: 28px;
    }
  }
  .border-green-btn {
    border: 1px solid $btn-green;
    color: $btn-green;
    background-color: transparent;
  }
  .newestcourse {
    background-color: $bg-offwhite;
    padding-block: 60px;
    @include tablet-lg {
      overflow: hidden;
    }
    h1 {
      font-family: $font600;
      color: $black-text-color;
      font-size: 30px;
    }
  }
  .address-section {
    border-radius: 5px;
    overflow: hidden;
  }
  .address-details {
    padding: 10px;
    padding-top: 20px;
    .icons {
      display: flex;
      @include tablet {
        width: 100%;
      }
    }
    p {
      display: flex;
      text-align: left;
      margin: 0;
      margin-left: 10px;
      font-size: 14px;
      color: $black-text-color;
    }
    a {
      margin-left: 10px;
      color: $black-text-color;
      font-family: $font300;
      text-decoration: none;
      font-size: 14px;
    }
  }
}
