@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";
.cta-section {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 60px 0px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    // background-color: #00000099;
    left: 0px;
    top: 0px;
    z-index: 99;
  }
  @include mobile{
    padding: 40px 0px;
    min-height: 250px;

  }
  .cta-content {
    z-index: 9999;
    position: relative;
    padding: 0px 60px 0px 60px;
    @include laptop{
    padding: 0px 40px 0px 40px;
    }
    @include tablet{
    padding: 0px 30px 0px 30px;
    }
    @include mobile{
    padding: 0px 15px 0px 15px;
    }
  }

  @include flex-align(center);
  min-height: 300px;
  h2 {
    text-align: left;
    @include mobile {
      font-size: 22px;
    }
  }
  h6 {
    text-align: left;
    color: $text-white;
    a {
      color: $text-white;
    }
  }
  .btn-white-border {
    background-color: transparent;
    color: $text-white;
    height: 54px;
    font-size: 18px;
    border: 1px solid $text-white;
    text-decoration: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    &:hover {
      background-color: #fff;
      color: $black-text-color;
    }
  }
}
