@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";
.InstructerProfile {
  margin-top: 66px;
  @include tablet-lg {
    margin-top: 0px;
  }
  .main-content {
    min-height: 780px;
    background-color: rgba($color: $bg-offwhite, $alpha: 0.8);
    margin-left: 200px;
    @include tablet-lg {
      margin-left: 0;
      padding-bottom: 58px;
    }
    .brdcumb-block {
      @include flex-justify(space-between);
    }
    .brd-link {
      color: $black-text-color;
      text-decoration: none;
      font-size: 18px;
      @include tablet-lg {
        font-size: 14px;
      }
    }
    .text-green {
      color: $text-green;
      @include tablet-lg {
        font-size: 14px;
      }
    }
  }
  .right-content {
    width: 100%;
    padding-top: 20px;
    padding-left: 0px;
    @include tablet-lg {
      padding-left: 0px;
    }
  }
  .course-details-main {
    .course-banner {
      display: flex;
      width: 100%;
      @include mobile {
        flex-direction: column;
      }
      .banner-box-big2 {
        height: 428px;
        object-fit: cover;
        object-position: center center;
        @include tablet-lg {
          height: 350px;
        }
        @include tablet {
          height: 250px;
        }
      }
    }
    .about-course {
      margin-top: 30px;
      border-radius: 10px;
      background-color: $bg-white;
      padding: 30px;

      @include tablet {
        padding: 15px;
        margin-bottom: 20px;
      }
      h1 {
        font-size: 30px;
        font-family: $fontRegular;
        color: $black-text-color;
        @include tablet {
          font-size: 22px;
        }
        @include mobile {
          font-size: 18px;
        }
      }
      p {
        color: $black-text-color;
        font-size: 18px;
        font-family: $font300;
        text-align: left;
      }
      .link-readmore {
        color: #481a20;
        text-decoration: none;
        font-size: 14px;
      }
      .read-or-hide {
        cursor: pointer;
      }
      ul {
        padding: 0;
        margin: 0;
        @include mobile {
          margin-bottom: 20px;
        }
        li {
          font-size: 18px;
          color: $black-text-color;
          font-family: $font300;
          position: relative;
          padding-left: 15px;
          @include mobile {
            font-size: 14px;
          }
        }
        ::before {
          position: absolute;
          content: "";
          width: 6px;
          height: 6px;
          background-color: $black-text-color;
          border-radius: 6px;
          left: 0px;
          top: 10px;
        }
      }
    }
  }
  .materials-block {
    h3 {
      font-size: 18px;
      font-family: $font600;
      margin-bottom: 0px;
      @include tablet-lg {
        margin-top: 20px;
      }
    }
    p {
      font-family: $font300;
      font-size: 14px;
    }
    .material-list {
      ul {
        margin: 0;
        padding: 0;
        .incomplete {
          @include flex-align(center);
          background-color: $bg-midgray;
          display: flex;
          align-items: center;
        }
        li {
          width: 100%;
          border-radius: 5px;
          background-color: $bg-white;
          padding: 15px;
          margin-bottom: 20px;
          .material-block {
            position: relative;
            align-items: center;
            flex: 1;
            @include flex-align(center);
            .list-icons {
              background-color: $bg-offwhite;
              width: 70px;
              height: 70px;
              border-radius: 70px;
              margin-right: 20px;
              @include justify-align(center, center);
            }
            .title-block {
              flex: 0.8;
              h4 {
                font-size: 18px;
                font-family: $font300;
                color: $black-text-color;
                margin-bottom: 0;
              }
              h6.time {
                font-size: 12px;
                color: $black-text-color;
                margin-top: 5px;
                align-items: center;
                display: flex;
              }
            }
            .course-status {
              position: absolute;
              top: 0;
              right: 0px;
            }
            .completed {
              color: $text-green;
            }
          }
        }
      }
    }
    h1 {
      font-size: 30px;
      @include tablet {
        font-size: 22px;
      }
      @include mobile {
        font-size: 18px;
      }
    }
  }
  .btn {
    border-radius: 10px;
  }
  .btn-green {
    background-color: $btn-green;
    color: #fff;
    font-size: 18px;
    height: 54px;
    line-height: 40px;
    min-width: 150px;
    text-transform: uppercase;
    &:hover {
      background-color: $bg-white;
      color: $text-green;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .popupinfo {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      font-family: $fontRegular;
    }
    .zoom-form {
      input {
        width: 100%;
        margin-top: 10px;
        border: 1px solid $bg-green;
        background-color: $bg-offwhite;
        border-radius: 5px;
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
  .popupSection {
    max-width: 615px;
    padding: 20px !important;
    width: 90%;
    margin: 0 1%;
    .popupcontent {
      align-items: flex-center !important;
    }
  }
  .popupinfo {
    .inst-image {
      width: 150px;
      height: 150px;
      overflow: hidden;
      border-radius: 150px;
      img {
        height: 150px;
        object-fit: cover;
        width: 100%;
      }
    }
    h3 {
      font-size: 18px;
      text-align: center;
      font-family: $font300;
      margin-top: 20px;
      margin-bottom: 0;
    }
    p {
      font-family: $font300;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    .star {
      margin: 0 5px;
    }
  }
  .on {
    color: #f9c32c;
  }
  .off {
    color: #9f9fa3;
  }
  .add-section {
    position: sticky;
    top: 90px;
    bottom: 0;
    /* z-index: 2; */
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .textAr {
    width: 360px;
    max-width: 100%;
    margin-top: 10px;
    border: 1px solid #3e8181;
    border-radius: 5px;
    resize: none;
    margin-bottom: 10px;
    padding: 10px;
    min-height: 90px;
    outline: none;
    box-shadow: none;
    font-size: 12px;
    font-family: $font300;
  }
  .inst-profile-img {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 20px;
    @include tablet {
      width: 250px;
      height: 250px;
    }
    @include mobile {
      width: 100%;
      height: 100%;
      min-width: 80px;
      border-radius: 10px;
      margin-bottom: 10px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .inst-info {
    h1 {
      font-size: 36px;
      font-family: $font600;
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      @include mobile {
        font-size: 22px;
        justify-content: space-between;
      }
      .ratings-number {
        font-size: 12px;
        margin-top: 6px;
        padding-left: 5px;
        min-width: auto;
        justify-content: flex-end;
        display: flex;
      }
    }
    p {
      margin-bottom: 20px;
    }
    ul {
      margin: 20px 0px;
      padding: 0px;
      li {
        margin: 0px;
        padding: 0px;
        margin-bottom: 10px;
        display: flex;
        a {
          color: inherit;
          text-decoration: none;
        }
        span {
          min-width: 32px;
          img {
            margin-right: 10px;
            height: 20px;
          }
        }
      }
    }
  }
  .review-block {
    h1 {
      margin-top: 50px;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        list-style-type: none;
        padding-left: 0px !important;
        width: 50%;
        display: inline-flex;
        @include tablet-lg {
          width: 100%;
          margin-bottom: 20px;
        }
        .user-review-info {
          h1 {
            font-size: 24px;
            font-family: $fontRegular;
            color: $black-text-color;
            margin: 0;
            align-items: center;
            display: flex;
            .ratings-number {
              padding-left: 15px;
            }
            @include tablet {
              font-size: 16px;
            }
            @include mobile {
              font-size: 14px;
            }
            span {
              font-size: 10px;
            }
          }
        }
        .reviewer-img {
          width: 72px;
          min-width: 72px;
          height: 72px;
          border-radius: 100%;
          overflow: hidden;
          margin-right: 20px;
          img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
        p {
          font-size: 18px;
          margin-top: 0;
        }
      }
      ::before {
        all: unset;
        display: none;
        padding: 0;
      }
    }
  }
}
