@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";

.ViewAllCourse {
  margin-top: 66px;
  overflow-x: hidden;

  @include tablet-lg {
    margin-top: 0px;
  }

  .course-main {
    .section-title {
      h1 {
        font-family: $fontRegular;
        margin-bottom: 0px;

        @include tablet-lg {
          font-size: 22px;
        }

        @include tablet {
          font-size: 18px;
        }
      }
    }
  }

  .main-content {
    min-height: 92vh;
    background-color: rgba($color: $bg-offwhite, $alpha: 0.8);
    margin-left: 200px;

    @include tablet-lg {
      margin-left: 0;
      padding-bottom: 58px;
    }

    .right-main-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: inherit;
    }
  }

  .right-content {
    width: 100%;
    padding-top: 20px;
    padding-right: 15px;
    padding-left: 50px;

    @include tablet-lg {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include tablet {
      padding-right: 30px;
    }

    @include mobile {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .courses-section {
    padding: 30px 0;
    .btn-green {
      align-self: center;
      background-color: $btn-green;
      color: $bg-white;
      border-radius: 5px;
      text-decoration: none;
      padding: 7px 28px;
      border: 1px solid $btn-green;
      min-width: 150px;

      &:hover {
        background-color: $bg-white;
        color: $text-green;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    .course-category {
      background: $bg-white;
      border-radius: 5px;
      padding: 36px 130px 36px 130px;
      @include laptop {
        padding: 35px 30px 35px 30px;
      }
      @include tablet-lg {
        padding: 25px 30px 25px 30px;
      }
      .course-category-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
          max-width: 500px;
        }
        @media (max-width: 576px) {
          display: flex;
          overflow: auto;
          width: 100%;
          max-width: unset;
        }
        .card-wrap {
          max-width: 250px;
          width: 100%;
          @media (max-width: 576px) {
            min-width: 250px;
            width: 100%;
          }
          @media (max-width: 425px) {
            min-width: 200px;
            width: 100%;
          }
          .course-box-highligthed {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 270px;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            margin-bottom: 25px;
            @include minmax-width(1150, 1600) {
              width: 244px;
            }
            span {
              position: absolute;
              z-index: 999;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              opacity: 0.4;
            }
            .course-block {
              padding: 15px;
              z-index: 999;
              position: absolute;
              width: 100%;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              height: 100%;

              h1 {
                color: $text-white;
                font-family: $fontRegular;
                font-size: 24px;
              }
              p {
                color: $text-white;
                font-family: $fontRegular;
                font-size: 12px;
                text-align: center;
              }

              .btn-white-border {
                background-color: transparent;
                color: $text-white;
                min-width: 100%;
                height: 54px;
                line-height: 42px;
                font-size: 18px;
                border: 1px solid $text-white;

                &:hover {
                  background-color: #fff;
                  color: $black-text-color;
                }
              }

              .f-30 {
                font-size: 30px;
                font-weight: 400;
              }
              .btn-view {
                border: 1px solid #ffffff;
                border-radius: 5px;
                padding: 12px 20px;
                background-color: transparent;
                color: $text-white;
                width: 100%;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;

                &:hover {
                  background-color: #fff;
                  color: $black-text-color;
                }
              }
            }
          }
          .course-box-disble {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 270px;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            margin-bottom: 25px;
            @include minmax-width(1150, 1600) {
              width: 244px;
            }
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0.5;
              background-color: black;
              z-index: 1;
            }
            span {
              position: absolute;
              z-index: 999;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              opacity: 0.4;
            }
            .course-block {
              padding: 15px;
              // z-index: 999;
              position: absolute;
              width: 100%;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              height: 100%;

              h1 {
                color: $text-white;
                font-family: $fontRegular;
                font-size: 24px;
              }
              p {
                color: $text-white;
                font-family: $fontRegular;
                font-size: 12px;
                text-align: center;
              }

              .btn-white-border {
                background-color: transparent;
                color: $text-white;
                min-width: 100%;
                height: 54px;
                line-height: 42px;
                font-size: 18px;
                border: 1px solid $text-white;

                &:hover {
                  background-color: #fff;
                  color: $black-text-color;
                }
              }

              .f-30 {
                font-size: 30px;
                font-weight: 400;
              }
              .btn-view {
                border: 1px solid #ffffff;
                border-radius: 5px;
                padding: 12px 20px;
                background-color: transparent;
                color: $text-white;
                width: 100%;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                z-index: 9;
                opacity: 0.4;
                &:hover {
                  background-color: #fff;
                  color: $black-text-color;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }

    h2 {
      color: $black-text-color;

      @include mobile {
        font-size: 22px !important;
      }
    }

    .course-box {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 270px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      margin-bottom: 25px;

      span {
        position: absolute;
        z-index: 999;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0.4;
      }

      .course-block {
        padding: 15px;
        z-index: 999;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;

        h1 {
          color: $text-white;
          font-family: $fontRegular;
          font-size: 24px;
        }

        p {
          color: $text-white;
          font-family: $fontRegular;
          font-size: 12px;
        }

        .btn-white-border {
          background-color: transparent;
          color: $text-white;
          min-width: 100%;
          height: 54px;
          line-height: 42px;
          font-size: 18px;
          border: 1px solid $text-white;

          &:hover {
            background-color: #fff;
            color: $black-text-color;
          }
        }
        .f-30 {
          font-size: 30px;
          font-weight: 400;
        }
        .btn-view {
          border: 1px solid #ffffff;
          border-radius: 5px;
          padding: 12px 20px;
          background-color: transparent;
          color: $text-white;
          width: 100%;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;

          &:hover {
            background-color: #fff;
            color: $black-text-color;
          }
        }
      }
    }
  }

  .courses-section {
    padding-block: 30px;

    .course-box {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 270px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      margin-bottom: 25px;

      span {
        position: absolute;
        z-index: 999;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0.4;
      }

      .course-block {
        padding: 15px;
        z-index: 999;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;

        // &::after {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     opacity: 0.4;
        //     background: #000;
        // }

        h1 {
          color: $text-white;
          font-family: $fontRegular;
          font-size: 24px;
        }

        .btn-white-border {
          background-color: transparent;
          color: $text-white;
          min-width: 100%;
          height: 54px;
          line-height: 42px;
          font-size: 18px;
          text-transform: uppercase;
          border: 1px solid $text-white;

          &:hover {
            background-color: #fff;
            color: $black-text-color;
          }
        }
      }
    }
  }

  .shwing-course {
    .slick-prev {
      z-index: 111;
      left: -30px;
    }

    .slick-next {
      z-index: 111;
      right: -21px;
    }
  }

  .quickButton {
    background-color: $bg-white;
    border-radius: 5px;
    height: unset;

    @include tablet-lg {
      min-height: unset;
      margin-bottom: unset;
      flex: 1;
      padding: 10px;
    }

    a {
      font-family: $font300;
      text-decoration: none;
      text-align: center;
      padding: unset;
      margin-right: 20px;
      color: $black-text-color;
      @include justify-align(center, center);

      @include mobile {
        margin-right: 0px;
      }

      flex-direction: row;

      @include mobile {
        flex-direction: column;
      }

      .bx-icon {
        height: unset;
        margin-left: 0;

        img {
          height: 18px;
        }

        @include tablet {
          img {
            height: 18px;
          }
        }
      }

      span {
        margin-top: unset;
        font-size: 14px;
        margin-left: 8px;

        @include tablet {
          font-size: 12px;
        }

        @include mobile {
          margin-left: 0px;
        }
      }
    }

    &:hover {
      // line-height: unset;
      // all: unset;
      .bx-icon {
        margin-left: 0;
      }

      a {
        color: $text-green;
        @include justify-align(center, center);
      }

      img {
        height: unset;
        filter: unset;
        line-height: unset;
      }

      span {
        margin-top: unset;
        font-size: 14px;
        margin-left: 8px;
        line-height: unset;

        @include tablet {
          font-size: 12px;
        }
      }
    }
  }

  .quick-buttons {
    max-width: 560px;
    width: 100%;
    display: flex;

    @media screen and (max-width:769px) {
      justify-content: space-around;
    }

    .right-data {
      display: flex;

      @media screen and (max-width:768px) {
        display: unset;
      }
    }

    .left-data {
      display: flex;

      @media screen and (max-width:768px) {
        display: unset;
      }
    }
  }

  .shwing-course {
    margin-top: 50px;

    @include tablet-lg {
      margin-top: 15px;
    }

    .section-title {
      @include justify-align(space-between, center);
      @include mobile {
        align-items: unset !important;
      }
      h4 {
        font-size: 22px;
      }

      span {
        color: $text-green;
      }

      .course-founded {
        font-family: $font300;
        font-size: 18px;
      }
    }
  }

  .cate-filter {
    margin-top: 50px;

    @include tablet-lg {
      margin-top: 15px;
    }

    .brd-link {
      text-decoration: none;
      color: #3e8181;
    }
  }
}

.search-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include tablet {
    align-items: unset;
    display: unset;
  }
  .btn-green {
    align-self: center;
    background-color: $btn-green;
    color: $bg-white;
    border-radius: 5px;
    text-decoration: none;
    padding: 7px 28px;
    border: 1px solid $btn-green;
    min-width: 150px;
    @include mobile {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }
    &:hover {
      background-color: $bg-white;
      color: $text-green;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .search-block {
    max-width: 80%;
    width: 100%;
    position: relative;

    .form-control {
      font-size: 16px;
      font-family: $font300;
      padding-right: 40px;
    }

    span {
      position: absolute;
      right: 25px;
      top: 36px;

      @include mobile {
        right: 9px;
      }
    }
  }
}
