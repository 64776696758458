@import "../../common/styles/variables.scss";
@import "../../common/styles/mixins.scss";
.MyCourse {
  margin-top: 66px;
  @include tablet-lg {
    margin-top: 0px;
  }
  .main-content {
    min-height: 780px;
    background-color: rgba($color: $bg-offwhite, $alpha: 0.8);
    margin-left: 200px;
    @include tablet-lg {
      margin-left: 0;
      padding-bottom: 58px;
    }
    .brdcumb-block {
      @include flex-justify(space-between);
    }
    .brd-link {
      color: $black-text-color;
      text-decoration: none;
      font-size: 18px;
      @include tablet-lg {
        font-size: 14px;
      }
    }
    .text-green {
      color: $text-green;
      @include tablet-lg {
        font-size: 14px;
      }
    }
    .radio-toolbar input[type="radio"] {
      display: none;
    }
    .radio-toolbar label {
      display: inline-block;
      padding-right: 50px;
      @include tablet-lg {
        font-size: 14px;
      }
      @include laptop {
        padding-right: 15px;
      }
      cursor: pointer;
    }
    .radio-toolbar input[type="radio"]:checked + label {
      color: $text-green;
      font-family: $font600;
    }

    .filter-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      margin-bottom: 30px;
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .filter-result {
      font-size: 14px;
      font-family: $font300;
    }
    .course-list-block {
      padding: 15px;
      background: $bg-white;
      border-radius: 5px;
      margin-bottom: 30px;
      @include mobile {
        margin-bottom: 15px;
      }
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      @include tablet {
        flex-direction: column;
      }
      @include flex;
      .course-thumbnail {
        max-width: 135px;
        height: 105px;
        margin-right: 20px;
        @include tablet {
          max-width: 100%;
          height: 200px;
          margin-right: 0px;
          margin-bottom: 10px;
        }
        flex: 1;
        display: flex;
        position: relative;
        @include tablet {
          display: flex;
        }
      }
      h3 {
        font-size: 16px;
        margin-bottom: 0;
      }
      h6 {
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 0;
      }
      .list-content {
        flex: 1;
        display: flex;
        justify-content: space-between;
      }
      .list-content-block {
        flex-direction: column;
        @include justify-align(space-between, flex-start);
        position: relative;
      }
      .btn-status-area {
        flex: 1 1;
        min-width: 120px;
        max-width: 120px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @include mobile {
          min-width: 120px;
          max-width: 120px;
        }
      }
      .list-date {
        font-size: 12px;
      }
      .link-green {
        color: $green;
        font-family: $fontRegular;
        font-size: 14px;
        text-decoration: none;
        float: right;
      }
      .course-status {
        font-size: 14px;
      }
      .course-favourite {
        position: absolute;
        right: 5px;
        top: 5px;
      }
      .btn-green {
        background-color: $btn-green;
        color: $text-white;
        min-width: 100px;
        height: 34px;
        line-height: 22px;
        font-size: 12px;
        text-transform: uppercase;
        &:hover {
          background-color: #fff;
          color: $btn-green;
        }
        @include tablet-lg {
          font-size: 12px;
          min-width: 80;
          height: 34px;
          line-height: 22px;
        }
      }
    }
    .course-thumbnail-img {
      width: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: 5px;
    }
  }
  .right-content {
    width: 100%;
    padding-top: 20px;
    padding-left: 0px;
    min-height: 750px;
    .inst-image {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 50px;
      @include mobile {
        margin-top: 20px;
      }
      img {
        height: 50px;
        object-fit: cover;
        width: 100%;
      }
    }
    .inst-information {
      display: flex;
      margin-left: 10px;
      flex-direction: column;
      h6 {
        font-size: 14px;
      }
      span {
        font-size: 10px;
      }
    }
    @include tablet-lg {
      padding-left: 0px;
    }
  }

  .course-favorite {
    position: relative;
    #topping {
      position: absolute;
      left: 0;
      top: 0;
      height: 25px;
      width: 25px;
      cursor: pointer;
      appearance: none;
      background: transparent;
    }
  }
}
